import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * post 请求 用户登录换取token
 * @param params
 * @returns {Promise<*>}
 */
export const userSignInService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/auth/login'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 拉取用户信息
 * @param params
 * @returns {Promise<*>}
 */
export const getUserInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/auth/info'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * 忘记密码
 * @type {string}
 */
export const getForgetPassWord = evnUrl.concat('/urp/s1')

/**
 * post 请求 用户注册
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const userRegisterService = async (params) => {
    try {
        params.requestStatus = 'USER_Register'  //添加注册请求临时状态 后期token走headers时去除
        const { data } = await request.postJson(evnUrl.concat('/user/register'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


<template>
    <div class="layout-body">
        <!--s: header-->
        <Header/>
        <!--e: header-->

        <div class="layout-login-box">
            <!--s: Data Welcome-->
            <div class="layout-prompt">
                It's {{ timeUnit() }} {{ dataUnit() }}. Welcome!
            </div>
            <!--e: Data Welcome-->

            <!--s: login Box-->
            <div class="login-box">
                <div class="input-box">
                    <input type="text" v-model="userInfo.username" class="check" placeholder="Email address"/>
                </div>
                <div class="input-box clear-border">
                    <input :type="!passwordVisible?'password':'text'" v-model="userInfo.password" class="check" placeholder="Password"/>
                    <a href="javascript:" class="input-visible" @click="onPasswordVisible">{{ passwordVisibleText }}</a>
                </div>
                <div class="login-btn">
                    <Button size="large" shape="circle" :loading="loadingStatus" @click="onSignInCheck">
                        <span v-if="!loadingStatus">Login</span>
                        <span v-else>Login...</span>
                    </Button>
                </div>
                <div class="login-forget">
                    <a href="javascript:">Forget your password?</a>
                </div>
            </div>
            <!--e: login Box-->
        </div>
    </div>
</template>
<script>
import Login from './login'
export default Login
</script>
<style lang="less">
@import "login";
</style>

import Vue from 'vue'
import { Button,Message } from 'iview';
Vue.component('Button', Button);
import { dataUnit,timeUnit } from '@/utils/tools'
import Cookies from 'js-cookie'
import Header from '@/components/Header/header.vue'
import { userSignInService,getUserInfoService } from '@/service/user-service/index'
import {mapMutations} from "vuex";
const Login = {
    name:'Login',
    components:{
        Header,
    },
    data() {
        return {
            dataUnit:dataUnit,
            timeUnit:timeUnit,
            passwordVisible:false,
            loadingStatus:false,
            passwordVisibleText:'show',
            userInfo:{
                username:'',
                password:'',
            }
        }
    },
    mounted() {
        window.addEventListener('keydown',this.onKeyDown)
    },
    methods:{
        ...mapMutations('userCenter',['SET_USER_NAME']),
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onSignInCheck()
            }
        },
        /**
         * 密码可显示
         */
        onPasswordVisible() {
            if(!this.passwordVisible){
                this.passwordVisible = true;
                this.passwordVisibleText = 'hide'
            }else{
                this.passwordVisible = false;
                this.passwordVisibleText = 'show'
            }
        },
        /**
         *  校验方法 目前只校验账户密码是否为空 通过之后开启防抖函数 调用登录方法
         *  @param account && password
         */
        onSignInCheck(){
            if(this.userInfo.username == '' || this.userInfo.password == ''){
                alert('请输入用户名或密码！')
                return;
            }
            this.loadingStatus = true
            this.onSignIn().then()
        },
        /**
         * 登录方法
         * @returns {Promise<void>}
         */
        async onSignIn() {
            try {
                let params = {
                    username:this.userInfo.username,
                    password:this.userInfo.password
                }
                const { code,token } = await userSignInService(params)
                if(token){
                    Cookies.set('token',token,{ expires: 1 })
                    this.onUserInfo().then()
                }
                if(code === 1001){
                    Message.error('Password invalid.')
                }
                if(code === 1002){
                    Message.error('Sorry，we couldn’t find your account.Please create one and enjoy. ')
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
        /**
         * 获取用户信息
         * @returns {Promise<void>}
         */
        async onUserInfo(){
            try {
                const { user } = await getUserInfoService({})
                this.SET_USER_NAME(user.username)
                this.$router.push('/catalogue')
                this.loadingStatus = false

            }catch (error){
                console.log(error)
                this.loadingStatus = false
            }
        }
    },
    destroyed() {
        window.removeEventListener('keydown',this.onKeyDown,false);
    }
}
export default Login
